<footer>
    <div class="container">
        <img src="assets/soma_light.png" alt="SOMA_LIGHT" class="soma_light">
        <div class="social-media">
            <ul>
                <li>
                    <h6 class="white">
                        Socialmedia:
                    </h6>
                </li>
                <li>
                    <a>
                        <i class="fa fa-facebook-official" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a>
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a>
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <h4 class="white subscribe">
                    Subscribe to our newsletter
                </h4>
                <div class="d-flex">
                    <span class="border_one border_bottom"></span>
                    <span class="border_two border_bottom"></span>
                </div> 

                <div class="email_address">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="enter your email address"
                        aria-label="Recipient's username" aria-describedby="basic-addon2">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <h4 class="white subscribe">
                  Quicklinks
                </h4>
                <div class="d-flex">
                  <span class="border_three border_bottom"></span>
                  <span class="border_four border_bottom"></span>
                </div>

                <div>
                    <ul>
                        <li>
                            <a class="white">Privacy Policy</a>
                        </li>
                        <li>
                          <a class="white">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-3">
                <h4 class="white subscribe">
                  Contact Us
                </h4>
                <div class="d-flex">
                  <span class="border_five border_bottom"></span>
                  <span class="border_six border_bottom"></span>
                </div>

                <div>
                  <ul>
                    <li>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      <a href="mailto:hi@soma-npt.ch">hi@soma-npt.ch</a>
                    </li>
                    <li>
                        <i class="fa fa-phone-square" aria-hidden="true"></i>
                      <a class="white">+91 3124 2175</a>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
        <div class="ready_to_this">
            <h5 class="ready_to_do_this">READY TO DO THIS</h5>
            <div>
                <h3>
                    LET'S <span class="color-blue">GET</span> STARTED
                </h3>
            </div>
            <div class="schedule_call">
                <a>SCHEDULE CALL</a>
            </div>
        </div>
        <div class="soma_rightside d-flex">
            <div class="custom_width">
                <div>
                  <img src="assets/logo.png" alt="logo">
                </div>
                <div class="d-flex">
                  <i class="fa fa-copyright" aria-hidden="true"></i>
                  <h6>

                    2020 <b>SOMA.</b>
                  </h6>
                </div>
            </div>           
        </div>
    </div>
</footer>