<section class="npt_vision">
    <div class="npt_background" [ngStyle]="{backgroundImage:'url(assets/soma-npm-vision-background.png)'}">
        <img src="assets/vission_ellipse.png" alt="npt_vision" class="npt_vision_ellipse">
        <div class="container">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-npt-tab" data-toggle="tab" href="#nav-npt" role="tab"
                    aria-controls="nav-npt" aria-selected="true">Soma NPT</a>
                    <a class="nav-item nav-link" id="nav-vision-tab" data-toggle="tab" href="#nav-vision" role="tab"
                    aria-controls="nav-vision" aria-selected="false">NPT Vision</a>
                    <a class="nav-item nav-link" id="nav-breathe-tab" data-toggle="tab" href="#nav-breathe" role="tab"
                    aria-controls="nav-breathe" aria-selected="false">NPT Breathe</a>
                    <a class="nav-item nav-link" id="nav-analytics-tab" data-toggle="tab" href="#nav-analytics" role="tab"
                        aria-controls="nav-analytics" aria-selected="false">Analytics</a>
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active soma_npt_content" id="nav-npt" role="tabpanel" aria-labelledby="nav-npt-tab">
                    <img src="assets/npt_left_side.png" alt="npt_vision" class="npt_vision_image">
                    <img src="assets/soma-npt-text.png" alt="npt_vision" class="npt_text">
                    <div class="npt-vision_detail">
                        <h1 class="white">
                            Soma NPT
                        </h1>
                        <p class="white">
                            Soma NPT is the ultimate mental toughness app. This app is designed to expand your mental
                            capacity by training your brain to handle more cognitive load.
                        </p>
                        <div>
                            <img src="assets/app-store.png" alt="app-store" class="app-sotre-image">
                        </div>
                    </div>
                    <img src="assets/npt-right-side.png" alt="npt_vision" class="npt_vision_right_image">
                </div>
                <div class="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                    <img src="assets/npt-vision-left-side.png" alt="npt_vision" class="npt_vision_image">
                    <img src="assets/soma-npt-text.png" alt="npt_vision" class="npt_text">
                    <div class="npt-vision_detail">
                        <h1 class="white">
                        Soma <br> NPT Vision
                        </h1>
                        <p class="white">
                        This app uses eye tracking technology and cognitive training drills to give the athlete a well
                        rounded sports training app with massive benefits to performance.
                        </p>
                        <div>
                        <img src="assets/app-store.png" alt="app-store" class="app-sotre-image">
                        </div>
                    </div>
                    <img src="assets/vision_right_side.png" alt="npt_vision" class="npt_vision_right_image">
                </div>
                <div class="tab-pane fade" id="nav-breathe" role="tabpanel" aria-labelledby="nav-breathe-tab">
                  <!-- <img src="assets/npt-vision-left-side.png" alt="npt_vision" class="npt_vision_image"> -->
                  <img src="assets/soma-npt-text.png" alt="npt_vision" class="npt_text">
                  <div class="npt-vision_detail">
                    <h1 class="white">
                      Soma <br> NPT Breathe
                    </h1>
                    <p class="white">
                      oma NPT Breathe is the ultimate recovery app. Cognitive training
                      requires cognitive recovery.
                    </p>
                    <div>
                      <img src="assets/app-store.png" alt="app-store" class="app-sotre-image">
                    </div>
                  </div>
                  <img src="assets/breathe-right-side.png" alt="npt_vision" class="npt_vision_right_image">
                </div>
                <div class="tab-pane fade" id="nav-analytics" role="tabpanel" aria-labelledby="nav-analytics-tab">
                    <img src="assets/soma-npt-text.png" alt="npt_vision" class="npt_text">
                  <div class="npt-vision_detail">
                    <h1 class="white">
                      Soma Analytics
                    </h1>
                    <p class="white">
                      Soma Analytics is an integrated system designed to work with Soma NPT and Soma NPT Vision
                      <br>
                      t is best that you schedule a call with us in order to discuss your specific requirements.
                    </p>
                    <div>
                      <img src="assets/app-store.png" alt="app-store" class="app-sotre-image">
                    </div>
                  </div>
                  <img src="assets/analytics_right_side.png" alt="npt_vision" class="npt_vision_right_image">
                </div>
            </div>
        </div>
    </div>
</section>

