<div>
  <app-header></app-header>
  <section class="banner_image" [ngStyle]="{backgroundImage:'url(assets/headerr.png)'}">
    <div class="container">
      <div class="row">
        <div class="banner-content">
          <div class="d-flex flex-column">
            <h6 class="color-blue">Welcome</h6>
            <h1 class="white manipulate_title">Manipulate the limits <br> of sports perfomance</h1>
            <p class="white content">Brain training neuro technology for athletes and high perfoming humans</p>
            <button type="button" class="btn btn-primary mt-5 discover_more">Discover More
              <img src="assets/arrow.png" class="arrow" alt="Discover_More">
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="overflow-hidden">
    <div class="feature_right_side_bottom_shadow">
      <img src="assets/brain_right_side_shadow.png" alt="shadow">
    </div>
    <div class="brain_endurance">
      
      <div class="row no-gutters">
        <div class="col-sm-6">
          <img src="assets/brain.png" class="brain_background_image" alt="Brain_Endurance_Training">
          <img src="assets/brain_endurance.png" class="brain_endurance_logo" alt="Brain_Endurance_Training">
        </div>
        <div class="col-sm-6" class="col-sm-6 brain_endurance_content" [ngStyle]="{backgroundImage:'url(assets/brain_back.png)'}">

          <div class="brain_title">
            <h2 class="white">What is <span class="color-blue"> Brain </span> <br> Endurance Training</h2>
          </div>

          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" dots="true" [config]="slideConfig" (init)="slickInit($event)"
            (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
            <div ngxSlickItem  class="slide">
              <p class="white brain__content">
                Brain Endurance Training (BET) is a cognitive
                training specifically designed to increase your
                resilience toward the sense of a fatigue.
              </p>
              <div class="d-flex slider__content">
                <span class="border-blue" ></span>
                <p class="white">
                  Science has proven that sports
                  performance is inhibited by a higher
                  perception of effort.
                </p>
              </div>
            </div>

            <div ngxSlickItem class="slide">
              <p class="white brain__content">
                Brain Endurance Training (BET) is a cognitive
                training specifically designed to increase your
                resilience toward the sense of a fatigue.
              </p>
              <div class="d-flex slider__content">
                <span class="border-blue"></span>
                <p class="white">
                  Science has proven that sports
                  performance is inhibited by a higher
                  perception of effort.
                </p>
              </div>
            </div>

            <div ngxSlickItem class="slide">
              <p class="white brain__content">
                Brain Endurance Training (BET) is a cognitive
                training specifically designed to increase your
                resilience toward the sense of a fatigue.
              </p>
              <div class="d-flex slider__content">
                <span class="border-blue"></span>
                <p class="white">
                  Science has proven that sports
                  performance is inhibited by a higher
                  perception of effort.
                </p>
              </div>
            </div>

            

            
          </ngx-slick-carousel>
          
        </div>
      </div>
    </div>
  </section>
  <section class="the_benefits">
    <app-benefit></app-benefit>
  </section>
  <section class="scientificaly_proven">
    <div class="container">
     
      <div class="proven__detail">
        
        <h2 class="white">
          <img src="assets/Ellipse-3.png" alt="scientifically_proven" class="ellipse">
          <span class="color-blue">
            Scientifically
          </span>
          Proven
        </h2>
        <p class="white">
          Studies on elite athletes using our technology Soma NPT showed these improvements.
        </p>
        <div class="container time_trial_table">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                aria-selected="true">Time Trial 5M</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                aria-selected="false">Time Trial 20M</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
                aria-selected="false">80% TTE</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="tte-tab" data-toggle="tab" href="#tte" role="tab" aria-controls="tte"
                aria-selected="false">65% TTE</a>
            </li>
          </ul>
            
          
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div class="row">
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h4>

                  </h4>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4 ">
                  <h4 class="white blue_shadow_right scientifically_table_header">
                    Improvement [BET]
                  </h4>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h4 class="white blue_shadow_right scientifically_table_header">
                    Control
                  </h4>
                </div>
              </div>
              <div class="row scientificaly_content light-grey-background">
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white">
                    Cognition
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    9.1-19%
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    2.6-6%
                  </h5>
                </div>
              </div>
              <div class="row scientificaly_content light-black-background">
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white">
                    Attention
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    9.1-19%
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    2.6-6%
                  </h5>
                </div>
              </div>
              <div class="row scientificaly_content light-grey-background">
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white">
                    Agility
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    9.1-19%
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    2.6-6%
                  </h5>
                </div>
              </div>
              <div class="row scientificaly_content light-black-background">
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white">
                    Error Rate Improvement
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    9.1-19%
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue">
                    2.6-6%
                  </h5>
                </div>
              </div>
              <div class="row scientificaly_content light-grey-background">
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white">
                    Repeated Sprint Ability
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue border-bottom">
                    9.1-19%
                  </h5>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                  <h5 class="white border-blue border-bottom">
                    2.6-6%
                  </h5>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="row">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h4>

                    </h4>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4 ">
                    <h4 class="white blue_shadow_right scientifically_table_header">
                      Improvement [BET]
                    </h4>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h4 class="white blue_shadow_right scientifically_table_header">
                      Control
                    </h4>
                  </div>
                </div>
                <div class="row scientificaly_content light-grey-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Cognition
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-black-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Attention
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-grey-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Agility
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-black-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Error Rate Improvement
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-grey-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Repeated Sprint Ability
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue border-bottom">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue border-bottom">
                      2.6-6%
                    </h5>
                  </div>
                </div>
            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div class="row">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h4>

                    </h4>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4 ">
                    <h4 class="white blue_shadow_right scientifically_table_header">
                      Improvement [BET]
                    </h4>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h4 class="white blue_shadow_right scientifically_table_header">
                      Control
                    </h4>
                  </div>
                </div>
                <div class="row scientificaly_content light-grey-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Cognition
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-black-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Attention
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-grey-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Agility
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-black-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Error Rate Improvement
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue">
                      2.6-6%
                    </h5>
                  </div>
                </div>
                <div class="row scientificaly_content light-grey-background">
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white">
                      Repeated Sprint Ability
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue border-bottom">
                      9.1-19%
                    </h5>
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                    <h5 class="white border-blue border-bottom">
                      2.6-6%
                    </h5>
                  </div>
                </div>
            </div>
             <div class="tab-pane fade" id="tte" role="tabpanel" aria-labelledby="tte-tab">
               <div class="row">
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h4>

                   </h4>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4 ">
                   <h4 class="white blue_shadow_right scientifically_table_header">
                     Improvement [BET]
                   </h4>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h4 class="white blue_shadow_right scientifically_table_header">
                     Control
                   </h4>
                 </div>
               </div>
               <div class="row scientificaly_content light-grey-background">
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white">
                     Cognition
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     9.1-19%
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     2.6-6%
                   </h5>
                 </div>
               </div>
               <div class="row scientificaly_content light-black-background">
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white">
                     Attention
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     9.1-19%
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     2.6-6%
                   </h5>
                 </div>
               </div>
               <div class="row scientificaly_content light-grey-background">
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white">
                     Agility
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     9.1-19%
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     2.6-6%
                   </h5>
                 </div>
               </div>
               <div class="row scientificaly_content light-black-background">
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white">
                     Error Rate Improvement
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     9.1-19%
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue">
                     2.6-6%
                   </h5>
                 </div>
               </div>
               <div class="row scientificaly_content light-grey-background">
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white">
                     Repeated Sprint Ability
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue border-bottom">
                     9.1-19%
                   </h5>
                 </div>
                 <div class="col-4 col-lg-4 col-md-4 col-sm-4">
                   <h5 class="white border-blue border-bottom">
                     2.6-6%
                   </h5>
                 </div>
               </div>
             </div>
          </div>

        </div>
      </div>
      <div class="data_above_text">
        <h6>The data above is all studies combined using <span class="color-blue">Soma NPT</span></h6>
      </div>
      </div>
      <img src="assets/sports_right.png" class="sports_right">

      <div>
        <img src="assets/trai_angle.png" class="traiangle_icon">
      </div>
      
  </section> 

  <section class="brain_endurance_training" [ngStyle]="{backgroundImage:'url(assets/sports_background.png)'}">
    <img src="assets/bet-border.png" class="black_border">
    <div class= "sports_tech_for">
      <div class="container">
        <img src="assets/Ellipse-4.png" class="sports_elipse">
        <h6 class="white">
          sports tech for
        </h6>
        <h3 class="black">
          Brain Endurance Training
        </h3>
        <div class="content">
          <p class="white">
            The cognitive tasks in Soma NPT are like a gym for your brain. We train the brain to fatigue and the brain
            responds by becoming more resilient to both mental and physical fatigue.
          </p>
          <br>
          <p class="white">
            The cognitive drills used in many brain training apps are not really designed for the athlete. Brain games
            in most training apps are more for the general public and have an entertainment factor. The level of
            endurance and mental capacity athletes and high performing humans need is much larger than the general
            population. The athlete needs cognitive drills that are relevant to the cognitive demands of their sport.
          </p>
          <br>
          <p class="white">
            We have developed the best brain training apps for athletes and for high performing individuals who want to
            get the edge, and continue to find a new peak in their performance.
          </p>
        </div>
      </div>
    </div>
  </section>

  
  <app-soma-npt></app-soma-npt>
  
  <app-workwithyou></app-workwithyou>
  <section class="feature_in">
    <div class="container_custom">
      <div class="feature_right_side_shadow">
        <img src="assets/top-shadow.png" alt="shadow">
      </div>
      <img src="assets/feature_left.png" alt="feature_image" class="feature_position_left">
      <div class="row">
        <div class="col-sm-6">
          <div class="  ">
            <img src="assets/ellipse.png" class="sports_elipse_footer">
            <h2 class="white">Feature <span class="color-blue"> In </span> <br></h2>
          </div>
          <div class="riverse">
            <img src="assets/riverse.png" class="image">
          </div>
        </div>
        <div class="col-sm-6 right_image">
          <div>
            <img src="assets/outside.png" class="image">
          </div>
          <div>
            <img src="assets/sporttech.png" class="image">
          </div>
        </div>
      </div> 
      <img src="assets/feature-right.png" alt="feature_image" class="feature_position_right">
    </div>
    <img src="assets/feature_bottom_shadow.png" alt="feature_in" class="feature_bottom_shadow">
  </section>

  <app-npt-vision></app-npt-vision>
  <app-faqs></app-faqs>
  <app-footer></app-footer>
</div>
