import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-npt-vision',
  templateUrl: './npt-vision.component.html',
  styleUrls: ['./npt-vision.component.css']
})
export class NptVisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
