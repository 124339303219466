import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workwithyou',
  templateUrl: './workwithyou.component.html',
  styleUrls: ['./workwithyou.component.css']
})
export class WorkwithyouComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
