<section class="we-work-with">
  <div class="row no-gutters">
      <div class="col-lg-2 col-md-12 col-sm-2 ">
          <h3 class="white">
              We Work with
          </h3>
          <p class="white">
              Who's using our technology
          </p>
          <a class="white">
              View All
          </a>
      </div>
      <div class="col-lg-10 col-md-12 col-sm-10">
          <div class="work_with_image_list">
              <div class="d-flex">
                  <div>
                    <img src="assets/work1.png" class="image1">
                  </div>
                  <div>
                    <ul>
                      <li>
                        <img src="assets/work2.png" class="image2">
                      </li>
                      <li>
                        <img src="assets/work4.png" class="image3">
                      </li>
                      <li>
                        <img src="assets/work6.png" class="image4">
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <img src="assets/work3.png" class="image5">
                      </li>
                      <li>
                        <img src="assets/work5.png" class="image6">
                      </li>
                      <li>
                        <img src="assets/work7.png" class="image7">
                      </li>
                    </ul>
                  </div>
              </div>   
          </div>
      </div>
  </div>
</section>

<section class="our_search">
  <div>
    <h4>
      Our research has been presented at
    </h4>
    <div class="row">
      <div class="col-sm-3">
        <img src="assets/r1.png" alt="research" class="image1">
      </div>
      <div class="col-sm-3">
        <img src="assets/r2.png" alt="research" class="image2">
      </div>
      <div class="col-sm-3">
        <img src="assets/r3.png" alt="research" class="image3">
      </div>
      <div class="col-sm-3">
        <img src="assets/r4.png" alt="research" class="image4">
      </div>
    </div>
  </div>
</section>