<section class="soma_npt">
  <img src="assets/soma-man.png" class="soma_man_image">
  <img src="assets/npt-top-dot-shadow.png" class="soma_npt_dot_shadow">
  <div class="feature_right_side_shadow">
    <img src="assets/soma-npt-right-shadow.png" alt="shadow">
  </div>

  <!-- <div class="soma_npt_background"  [ngStyle]="{backgroundImage:'url(assets/soma-npm.png)'}"> -->
  <div class="detail">

    <div class="container">

      <img src="assets/ellipse.png" class="sports_elipse">
      <div class="soma_details">
        <img src="assets/mobile.png" class="mobile_image">
        <div class="d-flex soma_npt_content">
          <h2 class="white">
            Soma
            <span class="color-blue">
              NPT
            </span>
          </h2>
          <div class="content">
            <p class="white">
              Soma NPT is the ultimate mental toughness app. This app is designed to expand your mental capacity by
              training your brain to handle more cognitive load. This is the best app for athletes who want to
              outlast
              their competition mentally and physically.
            </p>
          </div>
        </div>
        <img src="assets/soma-npt-border.png" alt="soma-npt" class="soma-npt-border">
        <div class="soma_npt_vision">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="about-tab" data-toggle="tab" href="#about" role="tab" aria-controls="home"
                aria-selected="true">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="vision-tab" data-toggle="tab" href="#vision" role="tab" aria-controls="profile"
                aria-selected="false">Vision</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="analytics-tab" data-toggle="tab" href="#analytics" role="tab"
                aria-controls="contact" aria-selected="false">Analytics</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="breathe-tab" data-toggle="tab" href="#breathe" role="tab" aria-controls="contact"
                aria-selected="false">Breathe</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
              <ul class="d-flex">
                <div>
                  <img src="assets/dot-border.png">
                </div>
                <div class="pl-4">
                  <li>
                    Brain training app for athletes who want to expand their ability to handle fatigue both
                    physical and
                    mental.
                  </li>
                  <li>
                    he only brain training sports technology on the market that has a detailed athlete monitoring
                    system
                  </li>
                  <li>
                    Over 45 cognitive drills specifically chosen to emulate the mental demands of sport
                  </li>
                  <li>
                    Cognitive analytics to monitor data and progress
                  </li>
                  <li>
                    lug and play BET programs
                  </li>

                  <li>
                    Connects athlete to coach
                  </li>
                  <li>
                    An easy to use, intuitive interface
                  </li>
                </div>
              </ul>
            </div>
            <div class="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="vision-tab">
              <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
                <ul class="d-flex">
                  <div>
                    <img src="assets/dot-border.png">
                  </div>
                  <div class="pl-4">
                    <li>
                      Brain training app for athletes who want to expand their ability to handle fatigue both
                      physical
                      and
                      mental.
                    </li>
                    <li>
                      he only brain training sports technology on the market that has a detailed athlete monitoring
                      system
                    </li>
                    <li>
                      Over 45 cognitive drills specifically chosen to emulate the mental demands of sport
                    </li>
                    <li>
                      Cognitive analytics to monitor data and progress
                    </li>
                    <li>
                      lug and play BET programs
                    </li>

                    <li>
                      Connects athlete to coach
                    </li>
                    <li>
                      An easy to use, intuitive interface
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div class="tab-pane fade" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
              <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
                <ul class="d-flex">
                  <div>
                    <img src="assets/dot-border.png">
                  </div>
                  <div class="pl-4">
                    <li>
                      Brain training app for athletes who want to expand their ability to handle fatigue both
                      physical
                      and
                      mental.
                    </li>
                    <li>
                      he only brain training sports technology on the market that has a detailed athlete monitoring
                      system
                    </li>
                    <li>
                      Over 45 cognitive drills specifically chosen to emulate the mental demands of sport
                    </li>
                    <li>
                      Cognitive analytics to monitor data and progress
                    </li>
                    <li>
                      lug and play BET programs
                    </li>

                    <li>
                      Connects athlete to coach
                    </li>
                    <li>
                      An easy to use, intuitive interface
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div class="tab-pane fade" id="breathe" role="tabpanel" aria-labelledby="breathe-tab">
              <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
                <ul class="d-flex">
                  <div>
                    <img src="assets/dot-border.png">
                  </div>
                  <div class="pl-4">
                    <li>
                      Brain training app for athletes who want to expand their ability to handle fatigue both
                      physical
                      and
                      mental.
                    </li>
                    <li>
                      he only brain training sports technology on the market that has a detailed athlete monitoring
                      system
                    </li>
                    <li>
                      Over 45 cognitive drills specifically chosen to emulate the mental demands of sport
                    </li>
                    <li>
                      Cognitive analytics to monitor data and progress
                    </li>
                    <li>
                      lug and play BET programs
                    </li>

                    <li>
                      Connects athlete to coach
                    </li>
                    <li>
                      An easy to use, intuitive interface
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- </div> -->
</section>