<section class="faq_content">
    <div class="container">
      <div class="faq_left_side_image">
        <img src="assets/faq_left_side.png" alt="faqs" class="image">
      </div>
      <div class="faq_right_side_image">
        <img src="assets/faq-right-side.png" alt="faqs" class="image">
      </div>
      <div class="faq_bottom_shadow">
        
        <img src="assets/faq_bottom_shadow.png" alt="faqs" class="image">
      </div>
        <img src="assets/faq-ellipse.png" alt="faqs" class="faq_ellipse">
        <div class="faqs">
            <img src="assets/FAQ.png" alt="faqs" class="faq_title">
        </div>
        <div class="faq__tab__detail">
            <div class="row ">
              <div class="col-3">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <a class="nav-link active" id="v-pills-general-tab" data-toggle="pill" href="#v-pills-general" role="tab"
                    aria-controls="v-pills-home" aria-selected="true">General</a>
                  <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab"
                    aria-controls="v-pills-profile" aria-selected="false">Soma NPT</a>
                  <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab"
                    aria-controls="v-pills-messages" aria-selected="false">Soma Vision</a>
                  <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab"
                    aria-controls="v-pills-settings" aria-selected="false">Soma Breathe</a>
                    <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab"
                      aria-controls="v-pills-settings" aria-selected="false">Soma Analytics</a>
                      <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings"
                        role="tab" aria-controls="v-pills-settings" aria-selected="false">BET</a>
                </div>
              </div>
              <div class="col-9">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-general" role="tabpanel"
                    aria-labelledby="v-pills-general-tab">
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                          <div class="panel panel-default" >
                            <div class="panel-heading" role="tab" id="headingone" >
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapseone" aria-expanded="false" aria-controls="collapseone">
                                  What devices are the applications available on?
                                </a>
                              </h4>

                            </div>
                            <div id="collapseone" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingone">
                              <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid.</div>
                            </div>
                          </div>
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapseTwo"
                                  aria-expanded="false" aria-controls="collapseTwo">
                                  Where is the data held?
                                </a>
                              </h4>

                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingTwo">
                              <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid.</div>
                            </div>
                          </div>
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingThree">
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  Cant I just talk myself out of the feeling of fatigue and
                                  push through?
                                </a>
                              </h4>

                            </div>
                            <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingThree">
                              <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid. </div>
                            </div>
                          </div>
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingfour">
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                  Cant I just talk myself out of the feeling of fatigue and
                                  push through?
                                </a>
                              </h4>

                            </div>
                            <div id="collapsefour" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingfour">
                              <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid. </div>
                            </div>
                          </div>
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingfive">
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                  Aren’t they just getting better at the test/drill?
                                </a>
                              </h4>

                            </div>
                            <div id="collapsefive" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingfive">
                              <div class="panel-body white">The point is not to get better at the test/drill. The goal is to
                                induce a
                                certain degree of cognitive fatigue that will add to the stress that you
                                receive already from your body during training. </div>
                            </div>
                          </div>
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingsix">
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                  How is using an iPad/iPhone going to really improve sports skills?
                                </a>
                              </h4>

                            </div>
                            <div id="collapsesix" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingsix">
                              <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid. </div>
                            </div>
                          </div>
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingseven">
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                  Is this type of training just for endurance athletes?
                                </a>
                              </h4>

                            </div>
                            <div id="collapseseven" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingseven">
                              <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid. </div>
                            </div>
                          </div>
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingeight">
                              <h4 class="panel-title">
                                <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                  href="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                  Where has your research been presented?
                                </a>
                              </h4>

                            </div>
                            <div id="collapseeight" class="panel-collapse collapse" role="tabpanel"
                              aria-labelledby="headingeight">
                              <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid. </div>
                            </div>
                          </div>
                           <div class="panel panel-default">
                             <div class="panel-heading" role="tab" id="headingnine">
                               <h4 class="panel-title">
                                 <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                                   href="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                   How much does cognitive fatigue impact performance?
                                 </a>
                               </h4>

                             </div>
                             <div id="collapsenine" class="panel-collapse collapse" role="tabpanel"
                               aria-labelledby="headingnine">
                               <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                                 accusamus terry richardson ad squid. </div>
                             </div>
                           </div>
                        </div>
                    </div>
                  <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingone">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseone" aria-expanded="false" aria-controls="collapseone">
                              What devices are the applications available on?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseone" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingone">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid.</div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Where is the data held?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingTwo">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid.</div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Cant I just talk myself out of the feeling of fatigue and
                              push through?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingThree">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingfour">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                              Cant I just talk myself out of the feeling of fatigue and
                              push through?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsefour" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingfour">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingfive">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                              Aren’t they just getting better at the test/drill?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsefive" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingfive">
                          <div class="panel-body white">The point is not to get better at the test/drill. The goal is to
                            induce a
                            certain degree of cognitive fatigue that will add to the stress that you
                            receive already from your body during training. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingsix">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                              How is using an iPad/iPhone going to really improve sports skills?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsesix" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingsix">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingseven">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                              Is this type of training just for endurance athletes?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseseven" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingseven">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingeight">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                              Where has your research been presented?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseeight" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingeight">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingnine">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                              How much does cognitive fatigue impact performance?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsenine" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingnine">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="v-pills-messages" role="tabpanel"
                    aria-labelledby="v-pills-messages-tab">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingone">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseone" aria-expanded="false" aria-controls="collapseone">
                              What devices are the applications available on?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseone" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingone">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid.</div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Where is the data held?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingTwo">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid.</div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Cant I just talk myself out of the feeling of fatigue and
                              push through?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingThree">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingfour">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                              Cant I just talk myself out of the feeling of fatigue and
                              push through?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsefour" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingfour">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingfive">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                              Aren’t they just getting better at the test/drill?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsefive" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingfive">
                          <div class="panel-body white">The point is not to get better at the test/drill. The goal is to
                            induce a
                            certain degree of cognitive fatigue that will add to the stress that you
                            receive already from your body during training. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingsix">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                              How is using an iPad/iPhone going to really improve sports skills?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsesix" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingsix">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingseven">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                              Is this type of training just for endurance athletes?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseseven" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingseven">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingeight">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                              Where has your research been presented?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseeight" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingeight">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingnine">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                              How much does cognitive fatigue impact performance?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsenine" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingnine">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="v-pills-settings" role="tabpanel"
                    aria-labelledby="v-pills-settings-tab">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingone">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseone" aria-expanded="false" aria-controls="collapseone">
                              What devices are the applications available on?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseone" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingone">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid.</div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Where is the data held?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingTwo">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid.</div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Cant I just talk myself out of the feeling of fatigue and
                              push through?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingThree">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingfour">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                              Cant I just talk myself out of the feeling of fatigue and
                              push through?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsefour" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingfour">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingfive">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                              Aren’t they just getting better at the test/drill?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsefive" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingfive">
                          <div class="panel-body white">The point is not to get better at the test/drill. The goal is to
                            induce a
                            certain degree of cognitive fatigue that will add to the stress that you
                            receive already from your body during training. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingsix">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                              How is using an iPad/iPhone going to really improve sports skills?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsesix" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingsix">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingseven">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                              Is this type of training just for endurance athletes?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseseven" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingseven">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingeight">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                              Where has your research been presented?
                            </a>
                          </h4>

                        </div>
                        <div id="collapseeight" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingeight">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingnine">
                          <h4 class="panel-title">
                            <a class="collapsed white" data-toggle="collapse" data-parent="#accordion"
                              href="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                              How much does cognitive fatigue impact performance?
                            </a>
                          </h4>

                        </div>
                        <div id="collapsenine" class="panel-collapse collapse" role="tabpanel"
                          aria-labelledby="headingnine">
                          <div class="panel-body white">Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        
    </div>
</section>