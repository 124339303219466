import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soma-npt',
  templateUrl: './soma-npt.component.html',
  styleUrls: ['./soma-npt.component.css']
})
export class SomaNptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
