import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SportsComponent } from './sports/sports.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BenefitComponent } from './benefit/benefit.component';
import { WorkwithyouComponent } from './workwithyou/workwithyou.component';
import { SomaNptComponent } from './soma-npt/soma-npt.component';
import { NptVisionComponent } from './npt-vision/npt-vision.component';
import { FaqsComponent } from './faqs/faqs.component';
import { FooterComponent } from './footer/footer.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SportsComponent,
    AnalyticsComponent,
    BenefitComponent,
    WorkwithyouComponent,
    SomaNptComponent,
    NptVisionComponent,
    FaqsComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
