<div class="pl-0 pr-0">
    <!-- <div
      class="container d-flex flex-column flex-md-row align-items-center pl-0 pr-0 px-md-0 bg-white border-bottom shadow-sm header">
        <h5 class="my-0 mr-md-auto font-weight-normal">
            <a class="p-2" routerlink="/app">
                <img src="assets/logo.png">
            </a>
        </h5>
        <nav class="my-2 my-md-0 mr-md-3">
            <a class="p-2 Border Border-Bottom" href="#">Brain Endurance Training</a>
            <a class="p-2" routerLink="/sports">Sports tech</a>
            <a class="p-2" href="#">NPT</a>
            <a class="p-2" routerLink="/analytics">Analytics</a>
            <a class="p-2" href="#">We work with</a>
            <a class="p-2" href="#">Benefits</a>
            <a class="p-2" href="#">Faq</a>
        </nav>
        
    </div> -->

    <header>
      <nav
        class="navbar navbar-expand-md container d-flex flex-column flex-md-row bg-white border-bottom shadow-sm header">
        <a class="navbar-brand" routerlink="/app">
            <img src="assets/logo.png">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="p-2 Border Border-Bottom" href="#">Brain Endurance Training</a>
            </li>
            <li class="nav-item">
              <a class="p-2" routerLink="/sports">Sports tech</a>
            </li>
            <li class="nav-item">
              <a class="p-2" href="#">NPT</a>
            </li>
            <li class="nav-item">
              <a class="p-2" routerLink="/analytics">Analytics</a>
            </li>
            <li class="nav-item">
              <a class="p-2" href="#">We work with</a>
            </li>
            <li class="nav-item">
              <a class="p-2" href="#">Benefits</a>
            </li>
            <li class="nav-item">
              <a class="p-2" href="#">Faq</a>
            </li>
          </ul>
          
        </div>
      </nav>
    </header>

    <router-outlet></router-outlet>
</div>

