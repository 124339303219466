<div class="detail" >
  <div class="feature_right_side_shadow">
    <img src="assets/benefit-right-shadow.png" alt="shadow">
  </div>
    <div class="container">
        <div class="d-flex">
          <h2 class="white">
            The
            <span class="color-blue">
              benefits
            </span>     
          </h2>
          <div>
            <p class="white">
              Brain Endurance Training is the targeted and
              deliberate fatiguing of the brain with specific cognitive tasks
            </p>
          </div>
        </div>
        <div class="benefits_list">
          <img class="blue_border" src="assets/Rectangle-9.png">
          <div class="row details">  
            <img src="assets/Ellipse-1.png" class="benefit_elipse">
            <div class="col-lg-6 col-md-12 col-sm-12 ">
                <div>
                    <ul>
                        <li>
                            <p class="white">
                                <img src="assets/right.png">
                                Creates a larger capacity for mental and physical fatigue
                            </p>
                        </li>
                        <li>
                            <p class="white">
                                <img src="assets/right.png">
                                Increased ability to focus even with many distractions
                            </p>
                        </li>
                        <li>
                            <p class="white">
                                <img src="assets/right.png">
                                Enables an athlete to work harder for longer
                            </p>
                        </li>
                        <li>
                            <p class="white">
                                <img src="assets/right.png">
                                Reduces perception of effort
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12  benefit_right_content">
              <div>
                <ul class="">
                  <li>
                    <p class="white">
                        <img src="assets/right.png">
                        Increased accuracy
                    </p>
                  </li>
                  <li>
                    <p class="white">
                        <img src="assets/right.png">
                        Increses mental toughness
                    </p>
                  </li>
                  <li>
                    <p class="white">
                        <img src="assets/right.png">
                        Faster response speed & decision making
                    </p>
                  </li>
                </ul>
              </div>
              <img src="assets/Ellipse-2.png" class="benefit_elipse_bottom">
            </div>
            
          </div>
        </div>
        <!-- <img class="sports_image" src="assets/sports.png"> -->
    </div>
    <div class="feature_bottom_side_shadow">
      <img src="assets/npt-bottom-shadow.png" alt="shadow">
    </div>
    
    
</div>