import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SportsComponent } from './sports/sports.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AppComponent } from './app.component';
const routes: Routes = [
  { path: "sports", component: SportsComponent },
  { path: "analytics", component: AnalyticsComponent } ,
  { path: "app", component: AppComponent } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } export const 
RoutingComponent = [AnalyticsComponent, SportsComponent, AppComponent];
